<template>
  <div class="userFileFor">
    <div class="userFileForLabel" hpadding>{{ label }}</div>
    <div class="userFileForInputDummy">
      <p class="defaultInput activeInput" @click="$refs.collapse.toggle()">{{ $locale[availableFor] }}</p>
    </div>
    <Collapse ref="collapse" header="hidden" @open="targetPost = 'open'" @close="targetPost = 'close'">
      <Spacer num="1" />
      <div class="available-for">
        <ListItemCheck
          v-if="isEable('public')"
          icon="globe-americas"
          :text="$locale['object']['user-post'][`post-for-public-label`]"
          @click="select('public'), $refs.collapse.toggle()"
          :checked="availableFor === 'public'"
        />
        <ListItemCheck
          v-if="isEable('subscriber')"
          icon="user-plus"
          :text="$locale['object']['user-post'][`post-for-subscriber-label`]"
          @click="select('subscriber'), $refs.collapse.toggle()"
          :checked="availableFor === 'subscriber'"
        />
        <ListItemCheck
          v-if="isEable('purchase')"
          icon="creditCard"
          :text="$locale['object']['user-post'][`post-for-purchase-label`]"
          @click="select('purchase'), $refs.collapse.toggle()"
          :checked="availableFor === 'purchase'"
        />
      </div>
    </Collapse>
    <input type="hidden" :name="name" :value="availableFor" />
  </div>
</template>

<script>
export default {
  props: ["label", "value", "name", "enable"],
  data: function() {
    return {
      availableFor: this.value || "public",
    };
  },
  methods: {
    toggle: function() {
      this.$refs.collapse.toggle();
    },
    select: function(value) {
      this.availableFor = value;
      this.$emit("select", value);
    },
    isEable: function(_for) {
      if (!this.enable) return true;
      return this.enable.includes(_for);
    },
  },
};
</script>

<style lang="scss">
.userFileFor {
  .userFileForLabel {
    margin: 0 0 $mpadding/2 0;
  }
  .available-for {
    border: solid 1px $alto;
    border-radius: $mpadding/2;
  }
}
</style>
